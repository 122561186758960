<template>
  <div :class="$vuetify.breakpoint.lgAndUp? 'mt-12 ml-12 mr-12 pl-12 pr-12' : 'ma-3'" style="margin-bottom:200px">
      <v-layout justify-center align-center >
          <h1 style="font-size:32px; font-weight: 500;">We made it simple for you. Try first, pay later!</h1>
      </v-layout>

      <v-layout justify-space-between class="mt-12 pt-4 mb-12" v-if="$vuetify.breakpoint.mdAndUp">
          <v-card :style="$vuetify.breakpoint.lgAndUp? 'width:30%' : 'width: 33%'" rounded="lg" >
              <v-layout justify-center align-center class="pa-5 pt-7" column>
                  <h3 style="font-size:25px; font-weight: 500;">Free Plan</h3><br>
                  <p style="font-size:43px; font-weight: 400;">$0.00<span style="font-size:24px; font-weight: 300;">/m</span></p><br>
                  <v-layout justify-start class="ml-6 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                  </v-layout>
                  <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                  </v-layout>
                  <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                  </v-layout>
                  <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                  </v-layout>
                  <v-layout align-start class="ml-6 pl-2 mb-12" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                  </v-layout>

                  <v-btn color="primary" v-if="!loggedIn" @click="gotoSignup">Subscribe</v-btn>
                  <v-btn color="primary" v-else>Subscribed!</v-btn>


              </v-layout>



          </v-card>
          <v-card :style="$vuetify.breakpoint.lgAndUp? 'width:30%' : 'width: 33%'" rounded="lg"  >
            <v-layout justify-center align-center class="pa-5 pt-7 " column>
                <h3 style="font-size:25px; font-weight: 500;">Premium Plan</h3><br>
                <p style="font-size:25px; font-weight: 400;">Coming Soon..<span style="font-size:24px; font-weight: 300;"></span></p><br>
                <v-layout justify-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-12" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>

                <v-btn color="primary" disabled>Subscribe</v-btn>


            </v-layout>



          </v-card>
          <v-card :style="$vuetify.breakpoint.lgAndUp? 'width:30%' : 'width: 33%'" rounded="lg" class="corner" >
            <v-layout justify-center align-center class="pa-5 pt-7" column>
                <h3 style="font-size:25px; font-weight: 500;">Enterprise Plan</h3><br>
                <p style="font-size:25px; font-weight: 400;">Coming Soon..<span style="font-size:24px; font-weight: 300;"></span></p><br>
                <v-layout justify-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                </v-layout>
                <v-layout align-start class="ml-6 pl-2 mb-12" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>

                <v-btn color="primary" class="mb-5" disabled>Subscribe</v-btn>


            </v-layout>

          </v-card>

      </v-layout>

       <v-layout justify-center align-center class="mt-12 pt-4 mb-12" v-else column>
          <v-card style="width: 98%" max-width="400px" rounded="lg" >
              <v-layout justify-center align-center class="pa-5 pt-7" column>
                  <h3 style="font-size:25px; font-weight: 500;">Free Plan</h3><br>
                  <p style="font-size:43px; font-weight: 400;">$0.00<span style="font-size:24px; font-weight: 300;">/m</span></p><br>
                  <v-layout justify-start class="ml-12 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                  </v-layout>
                  <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                  </v-layout>
                  <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                  </v-layout>
                  <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                  </v-layout>
                  <v-layout align-start class="ml-12 pl-2 mb-12" style="width:100%" >
                      <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                  </v-layout>

                  <v-btn color="primary" v-if="!loggedIn" @click="gotoSignup" >Subscribe</v-btn>
                  <v-btn color="primary" v-else>Subscribed!</v-btn>


              </v-layout>



          </v-card>
          <v-card style="width:98%" rounded="lg" class="mt-8" max-width="400px">
            <v-layout justify-center align-center class="pa-5 pt-7" column>
                <h3 style="font-size:25px; font-weight: 500;">Premium Plan</h3><br>
                <p style="font-size:25px; font-weight: 400;">Coming Soon..<span style="font-size:24px; font-weight: 300;"></span></p><br>
                <v-layout justify-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-12" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>

                <v-btn color="primary" disabled>Subscribe</v-btn>


            </v-layout>



          </v-card>
          <v-card style="width:98%" rounded="lg" class="corner mt-8" max-width="400px" >
            <v-layout justify-center align-center class="pa-5 pt-7" column>
                <h3 style="font-size:25px; font-weight: 500;">Enterprise Plan</h3><br>
                <p style="font-size:25px; font-weight: 400;">Coming Soon..<span style="font-size:24px; font-weight: 300;"></span></p><br>
                <v-layout justify-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>1000 Requets per Month</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>No card info required</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-8" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>Free for lifetime</p>
                </v-layout>
                <v-layout align-start class="ml-12 pl-2 mb-12" style="width:100%" >
                    <p style="margin: 0px;font-size:18px;"><v-icon color="#677788" style="font-size:24px;" class="mr-8" left  >check_circle_outline</v-icon>50 Requests Daily</p>
                </v-layout>

                <v-btn color="primary" class="mb-5" disabled>Subscribe</v-btn>


            </v-layout>

          </v-card>

      </v-layout>
  </div>
</template>

<script>
export default {

    computed: {
        loggedIn: function(){
      return this.$store.state.auth.isLoggedIn;
    },
    },

    methods: {
    gotoSignup: function(){
      this.$router.push({ name : 'signup'})
    },
    }

}
</script>

<style>

.corner{
    width: 100%;
  background-image: url('../assets/corner.svg');
  background-position: right 0px top 0px;
}

</style>